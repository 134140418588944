<template>
  <v-container fluid>
      <!-- <global-FilterMenuNew disableTerminals /> -->
    <v-row>
      <global-EventFilterMenuTaking header='Takings'/>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-card
          dark
          outlined
        >
          <v-card-title class="secondary py-1 text-subtitle-1">
            <v-icon left dense> mdi-chart-pie </v-icon>
            Total Sales
          </v-card-title>
          <v-card-text >
            <v-row>
              <v-col lg="6" md="12" sm="6" cols="12">
                <skeletons-summary :loading="takingsTotalSalesLoading">

                  <v-card
                    class="primary mb-2"
                    dark
                  >
                    <v-card-title class="py-1 title">
                      <v-icon left> mdi-cash </v-icon>
                      Total Sales:
                    </v-card-title>
                    <v-card-text class="headline font-weight-bold" v-if="takingsTotalSales.hasOwnProperty('total_sales_data')">
                      <!-- $11,156,527,456.00 -->
                      {{takingsTotalSales.total_sales_data.NetSales | currency }}
                    </v-card-text>
                  </v-card>
                </skeletons-summary>
                
                <skeletons-summary :loading="takingsTotalSalesLoading">
                  <v-card
                    class="secondary mb-2"
                    dark
                  >
                    <v-card-title class="py-1 text-subtitle-1">
                      <v-icon left> mdi-cash </v-icon>
                      Cash Sales:
                    </v-card-title>
                    <v-card-text class="headline font-weight-bold" v-if="takingsTotalSales.hasOwnProperty('total_sales_data')">
                      <!-- $11,156,527,456.00 -->
                      {{takingsTotalSales.total_sales_data.Cash | currency }}
                    </v-card-text>
                  </v-card>
                </skeletons-summary>

                <skeletons-summary :loading="takingsTotalSalesLoading">
                  <v-card
                    class="secondary mb-2"
                    dark
                  >
                    <v-card-title class="py-1 text-subtitle-1">
                      <v-icon left> mdi-cash </v-icon>
                      Card Sales:
                    </v-card-title>
                    <v-card-text class="headline font-weight-bold" v-if="takingsTotalSales.hasOwnProperty('total_sales_data')">
                      <!-- $11,156,527,456.00 -->
                      {{takingsTotalSales.total_sales_data.Card | currency }}
                    </v-card-text>
                  </v-card>
                </skeletons-summary>

                <skeletons-summary :loading="takingsTotalSalesLoading">
                  <v-card
                    class="secondary mb-2"
                    dark
                  >
                    <v-card-title class="py-1 text-subtitle-1">
                      <v-icon left> mdi-cash </v-icon>
                      Voucher Sales:
                    </v-card-title>
                    <v-card-text class="headline font-weight-bold" v-if="takingsTotalSales.hasOwnProperty('total_sales_data')">
                      <!-- $11,156,527,456.00 -->
                      {{takingsTotalSales.total_sales_data.Voucher | currency }}
                    </v-card-text>
                  </v-card>
                </skeletons-summary>
              </v-col>

              <v-col lg="6" md="12" sm="6" cols="12">
                <skeletons-graph :loading="takingsTotalSalesLoading">
                  <charts-pie :chartdata="totalSalesDonutChart" :options="options" />
                </skeletons-graph>
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-card
          dark
          outlined
        >
          <v-card-title class="secondary py-1 text-subtitle-1">
            <v-icon left dense> mdi-chart-pie </v-icon>
            Stall Comparison
          </v-card-title>
          <v-card-text v-if="takingsStallComparisonDonutChart.datasets[0].data.length > 0">
            <skeletons-graph>
              <charts-pie :chartdata="takingsStallComparisonDonutChart" :options="options" />
            </skeletons-graph>
          </v-card-text>
          <v-card-text v-else>
            <label>No data available</label>
          </v-card-text>
        </v-card>
      </v-col>
      
    </v-row>

      <v-row>
        <v-col cols="12" >
        <v-card
          dark
          outlined
        >
          <v-card-title class="secondary py-1 text-subtitle-1">
            <v-icon left dense> mdi-chart-line </v-icon>
            Total Sales Timeline - Coming soon!
          </v-card-title>
          <v-card-text>
            <skeletons-graph :loading="takingsTotalSalesLoading">
              <charts-line :chartdata="totalSalesandStallComparisonTimelineChart" :options="options" />
            </skeletons-graph>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    
    <v-row>        
      <v-col cols="12">
        <v-card
          dark
          outlined
        >
          <v-card-title class="secondary py-1 text-subtitle-1">
            <v-icon left dense> mdi-file-table </v-icon>
            Stall Takings Table
          </v-card-title>
          <v-card-text class="pa-0">
            <v-data-table
              v-model="selectedTransaction"
              :headers="headers"
              :items="formattedStallTableData"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>        
      <v-col cols="12">
        <v-card
          dark
          outlined
        >
          <v-card-title class="secondary py-1 text-subtitle-1">
            <v-icon left dense> mdi-file-table </v-icon>
            Stall Tags Table
          </v-card-title>
          <v-card-text class="pa-0">
            <v-data-table
              v-model="selectedTransaction"
              :headers="headersForTagTable"
              :items="takingsTotalSales.stall_table_data"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>

  
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    
    selectedTransaction: {},
    headers: [
      { text: "Stall Name", value: "Stall Name"},
      { text: "Total Takings", value: "Sales"},
      { text: "Cash Takings", value: "Cash Takings"},
      { text: "Card Takings", value: "Card Takings"},
      { text: "Voucher Takings", value: "Voucher Takings"},
      { text: "# of Terminals", value: "Number of Terminals"},
      { text: "Avg $ per Terminal", value: "Average per Terminal"}
    ],
  
    headersForTagTable: [
      { text: "Tag Name", value: "Stall Name"},
      { text: "Total Takings", value: "Sales"},
      { text: "Cash Takings", value: "Cash Takings"},
      { text: "Card Takings", value: "Card Takings"},
      { text: "Voucher Takings", value: "Voucher Takings"},
      { text: "# of Terminals", value: "Number of Terminals"},
      { text: "Avg $ per Terminal", value: "Average per Terminal"}
    ],

    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        labels: {
            fontColor: "white"
        }
      },
      tooltips: {
        callbacks: {
            label(tooltipItem, data) {
              const totalValue = data.datasets[0].data.reduce((acc, value) => acc + parseInt(value), 0);
              const currentValue = parseInt(data.datasets[0].data[tooltipItem.index])
              const percentage = (currentValue/totalValue * 100).toFixed(2)
              return `${data.labels[tooltipItem.index]}: ${data.datasets[0].data[tooltipItem.index]} (${percentage})%`;
            }
        }
      }
    },
    pieChartData: {
      labels: [
        'Red',
        'Blue',
        'Yellow'
      ],
      datasets: [{
        label: 'My First Dataset',
        data: [300, 50, 100],
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(54, 162, 235)',
          'rgb(255, 205, 86)'
        ],
        hoverOffset: 10,
        hoverBorderWidth: 5
      }],
    },

    //loading
    stallComparisonLoading: true,
    timelineLoading: true,
    stallTableLoading: true
  }),
  computed: {
    ...mapState('dashboard', ['takingsTotalSales', 'takingsTotalSalesLoading']),
    
    totalSalesDonutChart () {
      return {
        labels: ['Cash', 'Card', 'Voucher'],
        datasets: [
          {
            backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
            data: [this.takingsTotalSales.total_sales_data.Cash, this.takingsTotalSales.total_sales_data.Card, this.takingsTotalSales.total_sales_data.Voucher],
            hoverBorderWidth: 3
          }
        ]
      }
    },
    takingsStallComparisonDonutChart () {
      return {
        labels: this.takingsTotalSales.stall_comparison_data.map(x => x['Stall Name']),
        datasets: [
          {
            backgroundColor: this.$generateColors.getColors(this.takingsTotalSales.stall_comparison_data.length),//['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],,
            data: this.takingsTotalSales.stall_comparison_data.map(x => x.Sales),
            hoverBorderWidth: 3
          }
        ]
      }
    },
    totalSalesandStallComparisonTimelineChart () {
      return {
        labels: this.takingsTotalSales.stall_comparison_timeline[0].Stalls.map(obj=> {
                  return obj.Timestamp
                }),
        datasets: this.takingsTotalSales.stall_comparison_timeline.map((obj, index) => {
          obj.label = obj['Stall Name']
          obj.borderColor = index === 0 ? '#FF0E00' : index === 1 ? '#649EB9' : 'blue',
          obj.pointBackgroundColor = 'white',
          obj.borderWidth = 1,
          obj.pointBorderColor = index === 0 ? '#FF0E00' : index === 1 ? '#649EB9' : 'blue',
          obj.backgroundColor = 'transparent',
          obj.data = obj.Stalls.map(obj=> {
                      return obj.SalesAmount
                     })
          return obj;
        })
      }
    },
    formattedStallTableData() {
      if (!this.takingsTotalSales || !this.takingsTotalSales.stall_table_data) {
        return [];
      }
      
      return this.takingsTotalSales.stall_table_data.map(item => ({
        'Stall Name': item['Stall Name'],
        'Total Takings': item['Sales'] !== undefined ? this.$options.filters.currency(item['Sales']) : 'N/A',
        'Cash Takings': item['Cash Takings'] !== undefined ? this.$options.filters.currency(item['Cash Takings']) : 'N/A',
        'Card Takings': item['Card Takings'] !== undefined ? this.$options.filters.currency(item['Card Takings']) : 'N/A',
        'Voucher Takings': item['Voucher Takings'] !== undefined ? this.$options.filters.currency(item['Voucher Takings']) : 'N/A',
        'Number of Terminals': item['Number of Terminals'],
        'Average per Terminal': item['Average per Terminal'] !== undefined ? this.$options.filters.currency(item['Average per Terminal']) : 'N/A',
    }));
    }
  },
  created() {
        var self = this
      setTimeout(
        function() {
          self.stallComparisonLoading = false
          self.timelineLoading = false
          self.stallTableLoading = false
        }, 2000);
  },
  filters: {
    currency(value) {
      var formatter = new Intl.NumberFormat('en-AU',{
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
      return '$' + formatter.format(value);
    }
  }
}
</script>

<style scoped>
h1{
  color: #a09c94;
  font-family: sans-serif;
}
</style>